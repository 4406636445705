import { Injectable } from '@angular/core';
import { ConfigService } from '../../config/config.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { ObservableHelper } from 'src/app/helpers/observable.helper';
import { UserService } from '../user/user.service';
import { map, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CustomerInformationResult } from '../user/user.model';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  observableHelper: ObservableHelper = new ObservableHelper();
  constructor(
    private readonly httpClient: HttpClient,
    private readonly configService: ConfigService,
    private readonly userService: UserService
  ) {}

  private readonly _userLoggedIn = new BehaviorSubject<boolean>(false);
  readonly userLoggedIn$ = this._userLoggedIn.asObservable();

  private readonly _userLoggedOut = new BehaviorSubject<boolean>(undefined);
  readonly userLoggedOut$ = this._userLoggedOut.asObservable();

  get userLoggedIn(): boolean {
    return JSON.parse(sessionStorage.getItem('isLoggedIn'));
  }

  set userLoggedIn(value: boolean) {
    sessionStorage.setItem('isLoggedIn', JSON.stringify(value));
    this._userLoggedIn.next(value);
  }

  loginLevelOne(customerId: string, password: string): Observable<boolean> {
    const data = {
      email: customerId,
      password,
    };

    return this.httpClient
      .post<SabLoginModel>(`${environment.LevelOneApiUrl}login`, data)
      .pipe(
        map((result) => {
          if (result) {
            this.userService.token = result.token;
            this.userService.expiry = result.expiry;
            this.userService.customerId = customerId;
            this.userService.userInfo = this.populateUserInfo(result.user);
            return true;
          }
          return false;
        })
      );
  }

  populateUserInfo(user: any): CustomerInformationResult {
    const userInfo: CustomerInformationResult = {
      Activated: true,
      Title: user.title,
      FirstName: user.name,
      SurName: user.surname,
      Name1: user.name,
      Name2: '',
      Name3: '',
      Name4: '',
      Name5: '',
      Name6: '',
      Address1: user.addressLine1,
      Address2: user.addressLine2,
      Address3: user.addressLine3,
      Address4: '',
      Address5: '',
      Address6: '',
      Address7: '',
      Address8: '',
      Address9: '',
      Address10: '',
      Address11: '',
      Address12: '',
      Address13: '',
      Address14: '',
      Address15: '',
      Address16: '',
      PostalCode: user.postcode,
      AddressKLADR: false,
      Country: '',
      LegalAddress1: '',
      LegalAddress2: '',
      LegalAddress3: '',
      LegalAddress4: '',
      LegalAddress5: '',
      LegalAddress6: '',
      LegalAddress7: '',
      LegalAddress8: '',
      LegalAddress9: '',
      LegalAddress10: '',
      LegalAddress11: '',
      LegalAddress12: '',
      LegalAddress13: '',
      LegalAddress14: '',
      LegalAddress15: '',
      LegalAddress16: '',
      LegalPostalCode: '',
      LegalAddressKLADR: false,
      LegalCountry: '',
      HomeTelNoAreaCode: '',
      HomeTelNo: user.homeNumber,
      MobileTelNo: user.mobileNumber,
      WorkTelNoAreaCode: 0,
      WorkTelNo: user.workNumber,
      WorkTelNoExt: 0,
      Fax: 0,
      EmailAddress: user.emailAddress,
      StopAllMail: false,
      Company: '',
      CompanyType: '',
      CompanyVehicleDriver: false,
      CompanyAddress1: '',
      CompanyAddress2: '',
      CompanyAddress3: '',
      CompanyAddress4: '',
      CompanyAddress5: '',
      CompanyAddress6: '',
      CompanyAddress7: '',
      CompanyAddress8: '',
      CompanyAddress9: '',
      CompanyAddress10: '',
      CompanyAddress11: '',
      CompanyAddress12: '',
      CompanyAddress13: '',
      CompanyAddress14: '',
      CompanyAddress15: '',
      CompanyAddress16: '',
      CompanyPostalCode: '',
      CompanyAddressKLADR: false,
      CompanyCountry: '',
      AllowContactCustomer: false,
      PreferredRoofTopId: '',
    };

    return userInfo;
  }

  login(customerId: string, password: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.requestToken(customerId)
        .pipe(take(1))
        .subscribe(
          (result) => {
            if (result.Result.ErrorCode === 0) {
              this.checkPassword(result.Token, customerId, password)
                .pipe(take(1))
                .subscribe(
                  (checkPasswordResult) => {
                    if (checkPasswordResult.ErrorCode === 0) {
                      this.userService.token = result.Token;
                      this.userService.customerId = customerId;
                      this.userLoggedIn = true;
                      resolve(true);
                    } else {
                      reject(result.ErrorCode);
                    }
                  },
                  (error) => {
                    reject(error);
                  }
                );
            } else {
              reject(result.Result.ErrorCode);
            }
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  // TODO - security fix
  loginSecure(customerId: string, password: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.newAllInOneRequestTokenCustomerLogin(customerId, password)
        .pipe(take(1))
        .subscribe(
          (result) => {
            if (result.errorCode === 0) {
                this.userService.token = result.token;
                this.userService.customerId = customerId;
                this.userService.dataHubHash = result.hashCode
                resolve(true)
            } else if (result.hashCode === null || result.errorCode === 500) {
              reject(result.errorCode);
            }
            else {
              reject(result.Result.ErrorCode);
            }
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  loginToCRM(customerId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.httpClient.get(
        `${environment.solZAAPIUrl}/api/v1/getCustomerInfo?userId=${customerId}`,
      ).pipe(take(1))
        .subscribe(
          (result: any) => {
            if (result.hashCode && result.token) {
              this.userService.crmSelectedVehicle.next(result.CustomerInformationResult.SelectedVehicle)
              sessionStorage.setItem("BpNo", result.CustomerInformationResult.BpNo)
              this.userService.crmSelectedVehicle.next(result.CustomerInformationResult.SelectedVehicle)
              resolve(result);
            } else {
              reject(result)
            }

          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  loginToMapSecurely(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.requestAdminToken()
        .pipe(take(1))
        .subscribe(
          (result) => {
            if (result.errorCode === 0) {
                this.userService.token = result.token;
                this.userService.dataHubHash = result.hashCode
                resolve(true)
            } else {
              reject(result.Result.ErrorCode);
            }
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  loginOG(customerId: string, password: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.newAllInOneRequestToken(customerId)
        .pipe(take(1))
        .subscribe(
          (result) => {
            // console.log("resulto-->", result);
            if (result.errorCode === 0) {
              // TODO: Security Refactor

                this.userService.token = result.token;
                this.userService.customerId = customerId;
                this.userService.dataHubHash = result.hashCode
                resolve(true)
              // this.userService.token = result.token;
            } else {
              reject(result.Result.ErrorCode);
            }
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  loginTitan(username: string, password: string) {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': `application/x-www-form-urlencoded`,
    });
    const body = `grant_type=password&username=${username}&password=${password}&dealer_id=${this.configService.configSettings.titanCommunity}`;
    return this.httpClient
      .post<any>(`${environment.titanUrl}token`, body, { headers })
      .pipe(
        map((result) => {
          this.userService.token = result.access_token;
          this.userService.expiry = result.expires_in;
          this.userLoggedIn = true;
        })
      );
  }

  getUserInfoTitan(username: string): Observable<TitanCustomerModel> {
    return this.httpClient.get<TitanCustomerModel>(
      `${environment.titanUrl}Account/UserDetail?username=${username}`
    );
  }

  logOut(): Observable<boolean> {
    // return
    return this.httpClient
      .post<any>(
        `${this.configService.configSettings.serverUrl}${this.configService.configSettings.communityId}/ServiceOnline/ExpireToken`,
        new ExpireToken(this.userService, this.configService)
      )
      .pipe(
        map((result) => {
          if (result.Result.ErrorCode === 0) {
            sessionStorage.clear();
            this._userLoggedOut.next(true);
            return true;
          } else return false;
        })
      );
  }

  requestToken(customerId: string): Observable<any> {


    return this.httpClient.post(
      `${this.configService.configSettings.serverUrl}${this.configService.configSettings.communityId}/ServiceOnline/RequestToken`,
      new RequestToken(this.configService, customerId)
    );
  }

  newAllInOneRequestToken(customerId: string): any {
    return this.httpClient.post(
      'https://serviceonlinetoken.azurewebsites.net/api/serviceOnline/request-authorisation-token',

      new RequestTokenCircles(this.configService, customerId)
      // TO DO store that as the new data hash to be used by checkpassword
      // {
      //   customerId: 'dick.ben.test@byom.de',
      //   partnerId: 'BMWSADev',
      //   version: '1',
      //   communityId: 'APIDEVSA',
      //   password: '',
      //   roofTopId: '9991r1',
      // }
    );
  }
// TODO: use teh one on top
// || this.configService.configSettings.communityId === "AGMC"
// https://nodeexpresssolapi.azurewebsites.net
// http://localhost:4000
// debugger
// || this.configService.configSettings.communityId === "BMSGEKHT"
  newAllInOneRequestTokenCustomerLogin(customerId: string, password?: string): any {
    // debugger
    if (this.configService.configSettings.hasSolServer || this.configService.configSettings.communityId === "ADMUAT") {
      return this.httpClient.post(
        `${environment.solAPIUrl}/api/v1/requestAuthorization`,
        new RequestTokenCleanUp(this.configService, customerId, password)
      );

    }
    else if(this.configService.configSettings.isCRM) {
      return this.httpClient.post(
        `${environment.solAPIUrl}/api/v1/requestAuthorizationToken`,
        new RequestTokenCleanUp(this.configService, customerId, password)
      );
    }
    else {
      return this.httpClient.post(
        `${environment.solServerUrl}/api/serviceOnline/request-authorisation-token`,
        new RequestTokenCleanUp(this.configService, customerId, password)
      );

    }
  }

  requestAdminToken(): any {
    if (this.configService.configSettings.isCRM || this.configService.configSettings.hasSolServer) {
      return this.httpClient.post(
        `${environment.solAPIUrl}/api/v1/requestAdmin`,
        new RequestAdminToken(this.configService));
    } else {
      return this.httpClient.post(
        `${environment.solServerUrl}/api/serviceOnline/request-admin-token`,
        new RequestAdminToken(this.configService)

      // return this.httpClient.post(
      //   `${environment.solServerUrl}/api/serviceOnline/request-admin-token`,
      //   new RequestAdminToken(this.configService)

        // new RequestTokenCleanUp(this.configService, "",  "M7A2O9P5*l$w")
      );
    }

  }

  checkPassword(
    token: string,
    customerId: string,
    password: string
  ): Observable<any> {
    const headers = this.userService.generateAuthorizationHeader(
      token,
      customerId,
      password
    );
    return this.httpClient.post(
      `${this.configService.configSettings.serverUrl}${this.configService.configSettings.communityId}/ServiceOnline/CheckPassword`,
      new CheckPassword(this.configService),
      { headers }
    );
  }

  activateToken(): Observable<any> {
    return this.httpClient.post(
      `${this.configService.configSettings.serverUrl}${this.configService.configSettings.communityId}/ServiceOnline/ActivateToken`,
      {}
    );
  }
}

export class TitanCustomerModel {
  Id: number;
  DealershipId: number;
  StatusCode?: number;
  Name: string;
  FirstName: string;
  MiddleName: string;
  LastName: string;
  Email: string;
  ThirdPartyCustomerId?: string;
  Note: string;
  HomePhone: string;
  Mobile: string;
  WorkPhone: string;
  DmsCustomerKey?: string;
  StreetAddress: AddressModelTitan;
  PostalAddress?: AddressModelTitan;
  ContactCustomer: string;
  IsOrganisation: boolean;
  IsSendConfirmEmail: boolean;
}

class AddressModelTitan {
  StreetNo: string;
  AddressType?: number;
  StreetLine1: string;
  StreetLine2: string;
  Suburb: string;
  State: string;
  PostCode: string;
  CountryCode?: number;
  SubDistrict?: string;
}

class SabLoginModel {
  token: string;
  sabToken: string;
  expiry: number;
  user: any;
}

class ExpireToken {
  constructor(userService: UserService, configService: ConfigService) {
    this.CustomerId = userService.customerId;
    this.PartnerId = configService.configSettings.partnerId;
    this.Token = userService.token;
    this.Version = configService.configSettings.version;
  }
  CustomerId: string;
  PartnerId: string;
  Token: string;
  Version: string;
}

class CheckPassword {
  constructor(configService: ConfigService) {
    this.RoofTopId = configService.configSettings.dealerInclusion[0].dealerCode;
  }
  RoofTopId: string;
}

class RequestToken {
  constructor(configService: ConfigService, customerId: string) {
    this.CustomerId = customerId;
    this.PartnerId = configService.configSettings.partnerId;
    this.Version = configService.configSettings.version;
    this.CommunityId = configService.configSettings.communityId;
  }

  CustomerId: string;
  PartnerId: string;
  Version: string;
  CommunityId: string;
}

class RequestTokenCircles {
  constructor(configService: ConfigService, customerId: string) {
    // this.CustomerId = 'dick.ben.test@byom.de';
    // this.PartnerId = 'BMWSADev';
    // this.Version = '1';
    // this.CommunityId = '9991r1';

    this.customerId = customerId;
    this.partnerId = configService.configSettings.partnerId;
    this.version = configService.configSettings.version;
    this.communityId = configService.configSettings.communityId;
    this.password = configService.configSettings.activationPassword;
    // this.roofTopId = 'BMTHAPTB1B';
  }

  customerId: string;
  partnerId: string;
  version: string;
  communityId: string;
  password: string;
  roofTopId: string;
}

class RequestTokenCleanUp {
  constructor(configService: ConfigService, customerId: string, password: string) {


    this.customerId = customerId;
    this.partnerId = configService.configSettings.partnerId;
    this.version = configService.configSettings.version;
    this.communityId = configService.configSettings.communityId;
    this.password = password;
    this.roofTopId = configService.configSettings.dealerInclusion[0].dealerCode;
  }

  customerId: string;
  partnerId: string;
  version: string;
  communityId: string;
  password: string;
  roofTopId: string;
}

class RequestAdminToken {
  constructor(configService: ConfigService) {
    this.partnerId = configService.configSettings.partnerId;
    this.version = configService.configSettings.version;
    this.communityId = configService.configSettings.communityId;
    this.roofTopId = configService.configSettings.dealerInclusion[0].dealerCode;
  }

  partnerId: string;
  version: string;
  communityId: string;
  roofTopId: string;
}
