import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, Subject } from 'rxjs';
import { UserService } from '../user-account/user/user.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  errorCode: number;
  errorDescription: string;
  showDefaultHeading: boolean;
  dealerShipName = "Dealer";
  dealershipPhoneNumber = "their phone number"

  constructor(
    private readonly translate: TranslateService,
    private userService: UserService
  ) {}
  isInErrorState = new Subject<boolean>();

  show(errorCode: number, showDefaultHeading: boolean = true) {
    this.errorCode = errorCode;


    this.getErrorMessage(errorCode).subscribe((description) => {
      this.errorDescription = description;
      this.isInErrorState.next(true);
      this.showDefaultHeading = showDefaultHeading;
    });
  }

  hide() {
    this.errorCode = 0;
    this.errorDescription = '';
    this.isInErrorState.next(false);
  }

  getErrorMessage(errorNumber: number): Observable<string> {
    const error = this.errors.filter((x) => x.value === errorNumber)[0];
    if (error.value === 24 || error.value === 12) {
      return this.translate.get(`error.${error.value}`);
    }
    if(errorNumber === 991) {
      this.dealerShipName = this.userService.selectedDealer.DealershipName;
      this.dealershipPhoneNumber = this.userService.selectedDealer.Telephone
      return of(`No working dates found. Please contact ${this.dealerShipName} at ${this.dealershipPhoneNumber}. `)
    }
    return of(error.description);
  }

  errors = [
    {
      name: 'workshop',
      value: 991,
      description: `No working dates found.`,
    },
    {
      name: 'Booking Failure',
      value: 992,
      description: 'Something Went Wrong, Please Try Again Later',
    },
    {
      name: 'success',
      value: 0,
      description: 'success',
    },
    {
      name: 'failure',
      value: 1,
      description: 'Failure',
    },
    {
      name: 'deferred',
      value: 2,
      description: 'Deferred',
    },
    {
      name: 'requestcannotbenull',
      value: 3,
      description: 'Request cannot be null',
    },
    {
      name: 'setuperror',
      value: 8,
      description: 'Setup or configuration error in the data hub',
    },
    {
       name: 'internalcodingerror',
      value: 9,
      description: 'Internalcoding error',
    },
    {
      name: 'insufficientcredentials',
      value: 10,
      description: 'Insufficient credentials',
    },
    {
      name: 'customeralreadyregistered',
      value: 11,
      description: 'Customer is already registered',
    },
    {
      name: 'customerunknown',
      value: 12,
      description: 'Customer is unknown',
    },
    {
      name: 'customerregistrationerror',
      value: 13,
      description: 'Error by registration customer',
    },
    {
      name: 'rooftopunknown',
      value: 14,
      description: 'Rooftop not known',
    },
    {
      name: 'tokenunknown',
      value: 15,
      description: 'Token is unknown',
    },
    {
      name: 'tokenillegal',
      value: 16,
      description: 'Illegal token received',
    },
    {
      name: 'mustchangepassword',
      value: 17,
      description: 'Password must be changed',
    },
    {
      name: 'mustchangeusername',
      value: 18,
      description: 'Username must be changed',
    },
    {
      name: 'maynotchangeusername',
      value: 19,
      description: 'May not change the username',
    },
    {
      name: 'vehicleunknown',
      value: 20,
      description: 'Vehicle is unknown',
    },
    {
      name: 'vehiclealreadyregistered',
      value: 21,
      description: 'Vehicle is already registered',
    },
    {
      name: 'newusernamesameasold',
      value: 22,
      description: 'User is same then the old username',
    },
    {
      name: 'usernamenoemailaddress',
      value: 23,
      description: 'The chosen username is no valid email address.',
    },
    {
      name: 'customernotactivated',
      value: 24,
      description: 'Customer not activated!',
    },
    {
      name: 'customeraccountlocked',
      value: 25,
      description: 'Customer account is locked',
    },
    {
      name: 'customeraccountalreadyunlocked',
      value: 26,
      description: 'Customer account already unlocked',
    },
    {
      name: 'customernoadministrator',
      value: 27,
      description: 'Customer is not an administrator',
    },
    {
      name: 'passwordtokenillegal',
      value: 28,
      description: 'The token to (re-) set the password is invalid.',
    },
    {
      name: 'passwordmismatch',
      value: 29,
      description: 'Password and password confirmation do not match.',
    },
    {
      name: 'appointmentunknown',
      value: 30,
      description: 'Appointment is unknown',
    },
    {
      name: 'scheduleappointmentfailed',
      value: 31,
      description: 'Schedule of the appointment has failed',
    },
    {
      name: 'postcodeoptionerror',
      value: 32,
      description:
        'Unfortunately your vehicle is located outside of our collection and delivery zone. please select a different appointment option.',
    },
    {
      name: 'parentappointmentunknown',
      value: 33,
      description: 'The parent appointment is unknown',
    },
    {
      name: 'parentappointmentmismatch',
      value: 34,
      description: 'When trying to ammend an appointment',
    },
    {
      name: 'parentappointmentnotamendable',
      value: 35,
      description: 'The parent appointment is not amendable',
    },
    {
      name: 'featurenotactive',
      value: 36,
      description: 'Feature not active.',
    },
    {
      name: 'partsorderunknown',
      value: 37,
      description: 'Parts order is unknown',
    },
    {
      name: 'mandatoryfieldsmissing',
      value: 38,
      description: 'Mandatory fields are missing',
    },
    {
      name: 'CustomerUnknownInDms',
      value: 39,
      description: 'The customer is registered in SOL but not yet in the DMS',
    },
    {
      name: 'polnotenabled',
      value: 40,
      description: 'Parts online not enabled.',
    },
    {
      name: 'solnotenabled',
      value: 41,
      description: 'Service online not enabled.',
    },
    {
      name: 'rooftopnotenabled',
      value: 42,
      description: 'Rooftop not enabled.',
    },
    {
      name: 'activationkeynotfound',
      value: 43,
      description: 'Activation key not found',
    },
    {
      name: 'passwordcomplexitymismatch',
      value: 44,
      description:
        'The password does not match the password complexity requirements.',
    },
    {
      name: 'wrongsecurityanswer',
      value: 45,
      description: 'Wrong answer to the security question',
    },
    {
      name: 'communityrooftopmismatch',
      value: 46,
      description: 'Community and/or rooftop do not match',
    },
    {
      name: 'datesoutofrange',
      value: 47,
      description:
        'The difference between initial date and end date is out of range (1 month).',
    },
    {
      name: 'missingsoapheader',
      value: 90,
      description: 'The soap header is missing!',
    },
    {
      name: 'missingdmsparameters',
      value: 91,
      description: 'DMS parameters are missing!',
    },
    {
      name: 'systemlocked',
      value: 92,
      description: 'System is not available at the moment.',
    },
    {
      name: 'schedulererror',
      value: 95,
      description: 'Error in a task started by the scheduler.',
    },
    {
      name: 'dmsreplyerror',
      value: 96,
      description: 'Error in the reply from the dms.',
    },
    {
      name: 'databaseerror',
      value: 97,
      description: 'Database error',
    },
    {
      name: 'dmscommunicationerror',
      value: 98,
      description: 'DMS not answering',
    },
    {
      name: 'unknownexception',
      value: 99,
      description: 'Undefined exception',
    },
    {
      name: 'dmserrorcode',
      value: 100,
      description: 'DMS error',
    },
    {
      name: 'wrongrooftopid',
      value: 101,
      description: 'Wrong rooftop id used',
    },
    {
      name: 'unabletogetrecommendedservices',
      value: 102,
      description: 'Unable to get recommended services',
    },
    {
      name: 'nocrmcompanyfound',
      value: 103,
      description: 'No crm company found',
    },
    {
      name: 'nocalendarsetup',
      value: 104,
      description: 'No calendar setup in dms',
    },
    {
      name: 'nooptionsavailable',
      value: 105,
      description: 'No options available',
    },
    {
      name: 'notechniciansavailableforchosendate',
      value: 106,
      description: 'No technicians available for chosen date',
    },
    {
      name: 'nosecondappointmenttimerequired',
      value: 107,
      description: 'No second appointment time required',
    },
    {
      name: 'notimeavailableforbookingoptionchosen',
      value: 108,
      description: 'No time available for chosen booking option',
    },
    {
      name: 'incorrectappointmentoptionselected',
      value: 109,
      description: 'Incorrect selected option for apointment',
    },
    {
      name: 'noskillsdefinedinjobsrequested',
      value: 110,
      description: 'No skills defined in job request',
    },
    {
      name: 'advisortimesnotsetup',
      value: 111,
      description: 'Advisor time not setup',
    },
    {
      name: 'unabletogetserviceadvisorsforthisrooftop',
      value: 112,
      description: 'Unable to get service advisors for this rooftop',
    },
    {
      name: 'systemisnotavailableatthemoment_pleasetrylater',
      value: 113,
      description: 'DMS not available at the moment! please try again later',
    },
    {
      name: 'novehicleregistrationfound',
      value: 114,
      description: 'Vehicle registration not found',
    },
    {
      name: 'unabletoaccessworkshopparameters',
      value: 115,
      description: 'Unable to access workshop parameters',
    },
    {
      name: 'errorsavingwip',
      value: 116,
      description: 'Error by saving wip',
    },
    {
      name: 'errorcreatingcustomer',
      value: 117,
      description: 'Error by creating customer',
    },
    {
      name: 'postcodeweigthingnotavailable',
      value: 118,
      description: 'Post code weigthing not available',
    },
    {
      name: 'incorrectjobrequested',
      value: 119,
      description: 'Incorrect job request',
    },
    {
      name: 'wrongappointmentoption_optioncode',
      value: 120,
      description: 'Wrong appointment option (wrong optioncode)',
    },
    {
      name: 'noappointmentoptionsdefined_noappointmentoptionsfile',
      value: 121,
      description: 'No appointment options defined',
    },
    {
      name: 'advisorbookedforsametimeslot',
      value: 122,
      description: 'Advisor booked for the same time slot',
    },
    {
      name: 'postcodeoptionerror_32',
      value: 123,
      description: 'Post code option error (32)',
    },
    {
      name: 'invalidwipno',
      value: 124,
      description: 'Invalid wipno for an appointment',
    },
    {
      name: 'noservicesprovided',
      value: 125,
      description: 'No services provided',
    },
    {
      name: 'wrongservicesprovided',
      value: 126,
      description: 'Wrong services provided',
    },
    {
      name: 'timeslotnolongeravailable',
      value: 127,
      description: 'Advisor timeslot no longer available',
    },
    {
      name: 'unabletocreatevehicle',
      value: 201,
      description: 'Unable to create vehicle',
    },
    {
      name: 'vehiclealreadycreatedinsystem',
      value: 202,
      description: 'Vehicle already exists in the system',
    },
    {
      name: 'vehiclealreadyallocatedtocustomer',
      value: 203,
      description: 'Vehicle already allocated to a customer',
    },
    {
      name: 'customerunkown',
      value: 204,
      description: 'Customer unknown',
    },
    {
      name: 'errordeletingvehicle',
      value: 205,
      description: 'Error by deleting vehicle',
    },
    {
      name: 'unabletogetvehicledetails',
      value: 206,
      description: 'Unable to read vehicle details',
    },
    {
      name: 'unabletoupdatevehicledetails',
      value: 207,
      description: 'Unable to update vehicle details',
    },
    {
      name: 'invalidchassisnumber',
      value: 208,
      description: 'Invalid chassis number',
    },
    {
      name: 'chassisnotfound',
      value: 209,
      description: 'Chassis number not found',
    },
    {
      name: 'registrationnumbernotfound',
      value: 210,
      description: 'Registration number not found',
    },
    {
      name: 'provideregistrationnumberorchassinumberforlookup',
      value: 211,
      description: 'Provide registration number or cahssis number for lookup',
    },
    {
      name: 'nodatafoundforselectedranges',
      value: 212,
      description: 'No data found for selected ranges',
    },
    {
      name: 'unabletoretrievecustomerdata',
      value: 301,
      description: 'Unable to retrieve customer data',
    },
    {
      name: 'unabletocreatecustomer',
      value: 302,
      description: 'Unable to create customer',
    },
    {
      name: 'alreadyexistscustomerwiththatemail',
      value: 303,
      description: 'Customer with that email address already exists',
    },
    {
      name: 'errorsavingcustomerdetails',
      value: 304,
      description: 'Error by saving customer details',
    },
    {
      name: 'temporarycompanynotfound',
      value: 305,
      description: 'Temporary company not found',
    },
    {
      name: 'unabletoretrievecompanydetails',
      value: 306,
      description: 'Unable to retrieve company details',
    },
    {
      name: 'nocompanydetailsforgivencustomer',
      value: 307,
      description: 'No company details for given customer found',
    },
    {
      name: 'noaddressesfoundforenteredpostcode',
      value: 308,
      description: 'No addresses found for the entered post code',
    },
    {
      name: 'temporarycompanynotupdated',
      value: 309,
      description: "Temporary company couldn't updated",
    },
    {
      name: 'companynotupdated',
      value: 310,
      description: "Company couldn't updated",
    },
    {
      name: 'unabletodeletecompanyrecord',
      value: 311,
      description: 'Unable to delete company record',
    },
    {
      name: 'companyrecordnotfound',
      value: 312,
      description: 'Company record not found',
    },
    {
      name: 'temporarycustomernotfound',
      value: 313,
      description: 'Temporary customer not found',
    },
    {
      name: 'couldnotstorecompanydetails',
      value: 314,
      description: 'Company details not stored',
    },
    {
      name: 'deliveryaddressexists',
      value: 315,
      description: 'Delivery address exist',
    },
    {
      name: 'deliveryaddressnotexists',
      value: 316,
      description: 'Delivery address not exist',
    },
    {
      name: 'nodatafoundforthesearchcriteria',
      value: 400,
      description: 'No data returned for the search criteria',
    },
    {
      name: 'deleteappointmentimpossible',
      value: 401,
      description:
        'Appointment could not be deleted (checks numberofdaysaptdeletablein in the community_rooftop_settings table db)',
    },
    {
      name: 'wrongcommunity',
      value: 501,
      description: 'Community does not exist',
    },
    {
      name: 'wrongrooftop',
      value: 502,
      description: 'Rooftop does not exist',
    },
    {
      name: 'dmsentrymissing',
      value: 503,
      description: 'No communication data available (dms entry missing)',
    },
    {
      name: 'rooftopinerrorstatus',
      value: 504,
      description:
        'Rooftop in error status (lastcallfailure > lastcallsuccess)',
    },
    {
      name: 'dmsidunknown',
      value: 505,
      description: 'DMS id unknown in dms table',
    },
    {
      name: 'rooftopunknownincommunity',
      value: 506,
      description: 'Rooftop is unknown in community',
    },
    {
      name: 'temporaryandpermanentcompanynumbers_filled',
      value: 507,
      description:
        'Temporary and permanent company numbers (both) are filled in',
    },
    {
      name: 'temporaryandpermanentcompanynumbers_empty',
      value: 508,
      description: 'Temporary and permanent company numbers (both) are empty',
    },
    {
      name: 'failedtocreateorderline',
      value: 600,
      description: 'Failed to create an order line',
    },
    {
      name: 'failedtocreatewip',
      value: 601,
      description: 'Failed to create wip in company',
    },
    {
      name: 'nolinesinwip',
      value: 602,
      description: 'No lines in wip',
    },
    {
      name: 'nodatafoundforpolsearchcriteria',
      value: 604,
      description: 'No data found for the selected ranges',
    },
    {
      name: 'dealercodeforcustomerblank',
      value: 605,
      description: 'Dealer code blank for customer',
    },
    {
      name: 'invalidcustomerid',
      value: 608,
      description: 'Invalid customer id',
    },
    {
      name: 'invalidslaccount',
      value: 609,
      description: 'Invalid sl account',
    },
    {
      name: 'invaliddearlercode',
      value: 610,
      description: 'Invalid dealer code',
    },
    {
      name: 'failedtoreadposoperator',
      value: 611,
      description:
        'Failed to read pos operator record for operator number %d userid %s opers handle',
    },
    {
      name: 'communitychangenotpossible',
      value: 999,
      description: 'Change of community is not possible',
    },
    {
      name: 'vehicleflaggedasdamagedbeyondrepairs',
      value: 1000,
      description:
        'You cannot add this vehicle, please contact us on 800BMW for more details.',
    },
  ];
}
